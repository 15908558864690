import { AttributeName, AttributeValue, FormattedVariantData } from '../../../utils/itemTools'

export type VariantFieldValues = Record<AttributeName, AttributeValue>
export const defaultPrescriptionItemFormValues = ({
  availableVariantSkus,
  usedAttributes,
  availableVariantOptions,
  attributeValues,
  productIndex,
  sku,
}: FormattedVariantData & { sku?: string } & {
  availableVariantOptions: Record<AttributeName, AttributeValue[]> | null
}): Record<AttributeName, AttributeValue> => {
  if (availableVariantSkus.length === 1) {
    return usedAttributes.reduce(
      (collection, attribute) => ({
        ...collection,
        [attribute]: availableVariantOptions?.[attribute][0],
      }),
      {}
    )
  }

  if (sku && availableVariantSkus.includes(sku)) {
    // if the sku is in the list of available skus, then we need to find the attribute values that match the sku
    return usedAttributes.reduce(
      (collection, attribute) => ({
        ...collection,
        [attribute]: productIndex[sku][attribute],
      }),
      {}
    )
  }

  // Select the first option of each attribute as default
  return usedAttributes.reduce(
    (collection, attribute) => ({
      ...collection,
      [attribute]: attributeValues[attribute][0],
    }),
    {}
  )
}
