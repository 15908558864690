import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'

import { GetBuilderProductsDocument, GetBuilderProductsQuery } from '../../../graphql/magento'
import { PrescriptionProduct } from '../../../lib/prescriptions/builder/types'
import { isNotNull } from '../../../utils/collectionTools'
import { ProductListEntry } from './ProductListEntry'
import { SkeletonProductListEntry } from './SkeletonProductListEntry'

type SimpleProduct = Extract<
  NonNullable<NonNullable<NonNullable<GetBuilderProductsQuery['products']>['items']>[number]>,
  { __typename?: 'SimpleProduct' }
>

const takeIfSimpleProduct = (
  item: NonNullable<NonNullable<GetBuilderProductsQuery['products']>['items']>[number] | undefined
): SimpleProduct | undefined => (item?.__typename === 'SimpleProduct' ? item : undefined)

export const ProductList: React.FC<{
  products: ReadonlyArray<PrescriptionProduct>
  onRemoveItem: (sku: string) => () => void
}> = ({ products, onRemoveItem }) => {
  const { data } = useQuery(GetBuilderProductsDocument, {
    returnPartialData: true,
    variables: useMemo(() => ({ sku: products.map(({ sku }) => sku).sort() }), [products]),
  })

  const itemsFromMagento = useMemo(
    () =>
      data?.products?.items
        ?.filter(isNotNull)
        ?.map(takeIfSimpleProduct)
        ?.filter(isNotNull)
        .map((p) => ({
          sku: p.sku || '',
          image: p.image?.url || '',
          name: p.name || '',
        })),
    [data?.products?.items]
  )

  const productsWithDataFromMagento = useMemo(
    () =>
      itemsFromMagento
        ? products.map((product) => ({
            ...(itemsFromMagento.find((item) => item.sku === product.sku) ?? {
              sku: product.sku,
              image: '',
              name: '',
            }),
            ...product,
          }))
        : null,
    [itemsFromMagento, products]
  )

  if (!productsWithDataFromMagento) {
    return (
      <>
        {products.map(({ sku }) => (
          <SkeletonProductListEntry key={sku} />
        ))}
      </>
    )
  }

  return (
    <>
      {productsWithDataFromMagento?.map((product) => (
        <ProductListEntry
          key={product.sku}
          product={product}
          onRemoveItem={onRemoveItem(product.sku)}
        />
      ))}
    </>
  )
}
