import React, { useMemo, useState } from 'react'

import { SimpleProductDetailsFragment } from '../../../graphql/magento'
import {
  closePrescriptionProductModal,
  PrescriptionProductModalData,
  usePrescriptionProductModalState,
} from '../../../lib/prescriptions/builder/product-modal'
import { ProductModal, ProductModalContent } from '../../products/ProductModal'
import { PrescriptionProductModalControls } from './PrescriptionProductModalControls'

const editHash = '#edit'

const PrescriptionProductModalContent: React.VFC<{
  onCloseModal: () => void
  modalData: PrescriptionProductModalData
}> = ({ onCloseModal, modalData }) => {
  const { openingSku, mode, item, defaultChildSku } = modalData

  const [selectedVariantSku, setSelectedVariantSku] = useState<string>(
    mode === 'edit' ? openingSku : defaultChildSku
  )

  const selectedVariant = useMemo<SimpleProductDetailsFragment | null>(
    () => item?.variants?.find((ver) => ver?.product?.sku === selectedVariantSku)?.product ?? null,
    [item, selectedVariantSku]
  )

  const retailPrice = undefined //TODO: replace with retailPrice from the search service when it's implemented

  return (
    <ProductModalContent
      onCloseModal={onCloseModal}
      modalData={modalData}
      selectedVariant={selectedVariant}
      retailPrice={retailPrice}
    >
      <PrescriptionProductModalControls
        onCloseModal={onCloseModal}
        modalData={modalData}
        selectedVariant={selectedVariant}
        selectedVariantSku={selectedVariantSku}
        setSelectedVariantSku={setSelectedVariantSku}
      />
    </ProductModalContent>
  )
}

export const PrescriptionProductModal: React.VFC = () => {
  const state = usePrescriptionProductModalState()
  if (state.type === 'CLOSED') {
    return null
  }
  return (
    <ProductModal
      state={state}
      onCloseModal={closePrescriptionProductModal}
      urlHash={state.type === 'OPEN' && state.data.mode === 'edit' ? editHash : undefined}
    >
      {state.type === 'OPEN' && (
        <PrescriptionProductModalContent
          onCloseModal={closePrescriptionProductModal}
          modalData={state.data}
        />
      )}
    </ProductModal>
  )
}
