import * as runtypes from 'runtypes'

import { eScript20230411Version } from './v2023-04-11'

export type EScriptProductData = runtypes.Static<typeof EScriptProductDataRunType>
export type EScriptData = runtypes.Static<typeof EScriptDataRunType>

const EScriptVersionRunType = runtypes.String.withConstraint((version) => {
  const validDateRegex = /^\d{4,}-\d{2}-\d{2}$/ // YYYY-MM-DD format
  if (!validDateRegex.test(version)) {
    return 'Version does not match "YYYY-MM-DD" date format'
  }
  const date = new Date(version)
  // this is the first version of the eScript spec, to be updated to handle ranges when
  // new versions are added
  const minDate = new Date(eScript20230411Version)
  if (date < minDate) {
    return `Version date must be greater than or equal to "${eScript20230411Version}"`
  }
  return true
})

const EScriptAutoShipIntervalRunType = runtypes.Union(
  runtypes.Literal('1m'),
  runtypes.Literal('2m'),
  runtypes.Literal('3m')
)

const EScriptCartOperationRunType = runtypes.Union(runtypes.Literal('replaceAll'))

const EScriptProductDataRunType = runtypes.Record({
  sku: runtypes.String,
  quantity: runtypes.Optional(runtypes.Number),
  autoShip: runtypes.Optional(runtypes.Union(runtypes.Null, EScriptAutoShipIntervalRunType)),
})

export const EScriptDataRunType = runtypes.Record({
  version: EScriptVersionRunType,
  dispensaryUrl: runtypes.String.withConstraint((baseUrl) =>
    baseUrl ? true : 'Must provide a dispensaryUrl'
  ),
  products: runtypes
    .Array(EScriptProductDataRunType)
    .withConstraint((products) =>
      products.length > 0 ? true : 'Must provide at least one product'
    ),
  promoCode: runtypes.Optional(runtypes.Union(runtypes.Null, runtypes.String)),
  operation: runtypes.Optional(runtypes.Union(runtypes.Null, EScriptCartOperationRunType)),
  eScriptCampaign: runtypes.Optional(runtypes.Union(runtypes.Null, runtypes.String)),
})
