import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import withDispensaryAccount from '../../../hoc/withDispensaryAccount'
import { Dispensary, getDispensaryBasePath } from '../../../lib/dispensaries'
import { generateEScriptLink } from '../../../lib/escript/generator'
import { PractitionerSettings } from '../../../lib/practitioner-settings/types'
import { prescriptionBuilderActions } from '../../../lib/prescriptions/builder/actions'
import { openPrescriptionProductModal } from '../../../lib/prescriptions/builder/product-modal'
import { Prescription } from '../../../lib/prescriptions/builder/types'
import {
  usePrescriptionBuilder,
  UsePrescriptionBuilderDispatch,
} from '../../../lib/prescriptions/hooks/usePrescriptionBuilder'
import ClickToCopy from '../../global/ClickToCopy'
import Spinner from '../../Spinner'
import DispensaryPromoSelector from './DispensaryPromoSelector'
import { PrescriptionProductModal } from './PrescriptionProductModal'
import { ProductList } from './ProductList'
import * as Styled from './styled'

const generateEScriptLinkAdapter = (dispensaryUrl: string, prescription: Prescription) => {
  if (typeof window === 'undefined') return ''
  return generateEScriptLink({
    dispensaryUrl,
    products: prescription.products.map((product) => ({
      sku: product.sku,
      quantity: product.quantity,
      autoShip: product.autoShip ? `${product.autoShip}m` : null,
    })),
    version: '',
    promoCode: prescription.promoCode || null,
    eScriptCampaign: 'bldr_unlabeled',
  })
}

const onRemoveItem = (dispatch: UsePrescriptionBuilderDispatch) => (sku: string) => () =>
  dispatch(prescriptionBuilderActions.remove(sku))

const onSelectPromoCode = (dispatch: UsePrescriptionBuilderDispatch) => (code: string) =>
  dispatch(prescriptionBuilderActions.setPromoCode(code))

const Builder: React.FC<{
  settings: PractitionerSettings
  dispensary: Dispensary
}> = ({ dispensary }) => {
  const location = useLocation()
  const dispensaryLink = useMemo(
    () =>
      `${location?.origin ?? 'https://www.designsforhealth.com'}${getDispensaryBasePath(
        dispensary.slug
      )}`,
    [location, dispensary.slug]
  )

  const { prescription, dispatch, loading } = usePrescriptionBuilder()

  const [toggleUndoInfoAlert, setToggleUndoInfoAlert] = useState(false)
  const clearAllEScript = () => {
    setToggleUndoInfoAlert(true)
  }

  const onUndo = () => {
    setToggleUndoInfoAlert(false)
  }

  const onClickSearch = () => {
    if (toggleUndoInfoAlert) {
      setToggleUndoInfoAlert(false)
      dispatch(prescriptionBuilderActions.clearAll())
    }
  }

  const [eScriptLink, setEScriptLink] = useState(
    generateEScriptLinkAdapter(dispensaryLink, prescription)
  )

  // Update the eScript link when the prescription changes
  useEffect(() => {
    setEScriptLink(generateEScriptLinkAdapter(dispensaryLink, prescription))
  }, [dispensaryLink, prescription])

  const products = useMemo(() => prescription.products, [prescription.products])
  const promoCode = useMemo(() => prescription.promoCode, [prescription.promoCode])

  if (loading) {
    return (
      <Styled.Wrapper>
        <Styled.Content>
          <Spinner loading />
        </Styled.Content>
      </Styled.Wrapper>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Heading>eScript Builder</Styled.Heading>
        <Styled.SubHeading>How to Use</Styled.SubHeading>
        <Styled.Instructions>
          To add products to this eScript you can either use the search bar located below, or click
          the &quot;Add to eScript&quot; button on the product page of your choice.
        </Styled.Instructions>
        <div onClick={onClickSearch}>
          <Styled.ProductListSearch
            onSelect={(product) =>
              openPrescriptionProductModal({
                sku: product.sku,
                urlKey: product.urlKey,
                mode: 'view',
              })
            }
          />
        </div>
        <PrescriptionProductModal />
        {toggleUndoInfoAlert && (
          <Styled.InfoAlert>
            <Styled.InfoAlertText>
              eScript cleared. Any links sent before will continue to work.
              <Styled.UndoLink onClick={onUndo}>Undo</Styled.UndoLink>
            </Styled.InfoAlertText>
          </Styled.InfoAlert>
        )}
        {products.length > 0 && !toggleUndoInfoAlert && (
          <>
            <Styled.ItemsContainer>
              <Styled.SubHeading>Share this link with your patients</Styled.SubHeading>
              <ClickToCopy copyText={eScriptLink} />
              <Styled.SubHeadingContainer>
                Products in this eScript
                <Styled.WarningButton onClick={clearAllEScript}>Clear eScript</Styled.WarningButton>
              </Styled.SubHeadingContainer>
              <ProductList products={products} onRemoveItem={onRemoveItem(dispatch)} />
            </Styled.ItemsContainer>
            <DispensaryPromoSelector
              promoCode={promoCode}
              onSelectPromoCode={onSelectPromoCode(dispatch)}
            />
          </>
        )}
      </Styled.Content>
    </Styled.Wrapper>
  )
}

const StyledSpinner = styled(Spinner)`
  // make the loading component be taller, so that the footer is not in the middle of the screen
  height: 70vh;
`

export default withAuthenticationRequired(
  withDispensaryAccount(Builder, {
    onLoading: () => <StyledSpinner loading />,
  }),
  {
    onRedirecting: () => <StyledSpinner loading />,
  }
)
