import { useAuth0 } from '@auth0/auth0-react'
import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { SimpleProductDetailsFragment } from '../../../graphql/magento'
import useUserAccessPolicy from '../../../hooks/useUserAccessPolicy'
import useUserCategory from '../../../hooks/useUserCategory'
import { prescriptionBuilderActions } from '../../../lib/prescriptions/builder/actions'
import { PrescriptionProductModalData } from '../../../lib/prescriptions/builder/product-modal'
import { AutoShipOption } from '../../../lib/prescriptions/builder/types'
import { usePrescriptionBuilder } from '../../../lib/prescriptions/hooks/usePrescriptionBuilder'
import { useTestKitProducts } from '../../../lib/products/hooks/useTestKitProducts'
import { userCategories } from '../../../utils/userClaims'
import { TestKitLegalTerms } from '../../products/details/TestKitLegalTerms'
import AutoShipSelector from './AutoShipSelector'
import { ItemVariantOptions } from './ItemVariantOptions'
import QuantitySelector from './QuantitySelector'

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const SaveButton = styled(PrimaryButton)(
  ({ theme }) => css`
    background: ${theme.colors.brand.blue};
    color: ${theme.colors.grayscale.white};
    width: 100%;
    max-width: 294px;
    text-transform: unset;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
  `
)

export const PrescriptionProductModalControls: React.VFC<{
  onCloseModal: () => void
  modalData: PrescriptionProductModalData
  selectedVariantSku: string
  setSelectedVariantSku: (sku: string) => void
  selectedVariant: SimpleProductDetailsFragment | null
}> = ({ onCloseModal, modalData, selectedVariantSku, setSelectedVariantSku, selectedVariant }) => {
  const { prescription, dispatch } = usePrescriptionBuilder()
  const { openingSku, openingUrlKey, mode, variantData } = modalData

  const prescriptionAutoShip = useMemo(
    () => prescription.products.find((p) => p.sku === openingSku)?.autoShip ?? null,
    [openingSku, prescription.products]
  )
  const prescriptionQuantity = useMemo(
    () => prescription.products.find((p) => p.sku === openingSku)?.quantity ?? 1,
    [openingSku, prescription.products]
  )

  const [autoShip, setAutoShip] = useState<AutoShipOption>(prescriptionAutoShip)
  const [quantity, setQuantity] = useState<number>(prescriptionQuantity)

  const hasAnythingChanged = useMemo(
    () =>
      autoShip !== prescriptionAutoShip ||
      quantity !== prescriptionQuantity ||
      selectedVariantSku !== openingSku,
    [autoShip, openingSku, prescriptionAutoShip, prescriptionQuantity, quantity, selectedVariantSku]
  )

  const isTestKit = useTestKitProducts().isTestKitSku(selectedVariant?.sku || '')
  const { isAuthenticated } = useAuth0()
  const isPatient = useUserCategory().userCategory === userCategories.patient
  const { canPurchaseTestKits } = useUserAccessPolicy()

  const showTestKitLegalTerms =
    mode === 'view' && isTestKit && isAuthenticated && !isPatient && canPurchaseTestKits

  // use form to validate required terms (if any)
  const formMethods = useForm()
  const { trigger } = formMethods

  const onSubmit = useCallback(() => {
    hasAnythingChanged &&
      dispatch(
        mode === 'edit'
          ? prescriptionBuilderActions.updateProduct(openingSku, {
              sku: selectedVariantSku,
              quantity,
              autoShip,
              urlKey: openingUrlKey,
            })
          : prescriptionBuilderActions.add({
              sku: selectedVariantSku,
              quantity,
              autoShip,
              urlKey: openingUrlKey,
            })
      )

    onCloseModal()
  }, [
    autoShip,
    dispatch,
    hasAnythingChanged,
    onCloseModal,
    mode,
    openingSku,
    openingUrlKey,
    quantity,
    selectedVariantSku,
  ])

  return (
    <>
      <ItemVariantOptions
        variantData={variantData}
        onVariantSelected={setSelectedVariantSku}
        selectedVariantSku={selectedVariantSku}
      />
      {!isTestKit && <AutoShipSelector autoShip={autoShip} onUpdateAutoShip={setAutoShip} />}
      {showTestKitLegalTerms && (
        // provide form context for TestKitLegalTerms
        <FormProvider {...formMethods}>
          <TestKitLegalTerms isDisabled={false} />
        </FormProvider>
      )}
      <ButtonContainer>
        <QuantitySelector quantity={quantity} onUpdateQuantity={setQuantity} />
        {mode === 'view' ? (
          <SaveButton
            onClick={(event) => {
              event.preventDefault()
              trigger()
                .then((isValid) => isValid && onSubmit())
                .catch(() => {
                  // noop
                })
            }}
          >
            Add to eScript
          </SaveButton>
        ) : (
          <SaveButton disabled={!hasAnythingChanged} onClick={onSubmit}>
            Save Changes
          </SaveButton>
        )}
      </ButtonContainer>
    </>
  )
}
