import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'

import { processProductModalSignals, productModalStateMethods } from '../../products/modal/state'
import {
  OpenProductModalInput,
  ProductModalData,
  ProductModalState,
} from '../../products/modal/types'

// modal state

export type PrescriptionProductModalData = ProductModalData & {
  mode: 'edit' | 'view'
}
export type PrescriptionProductModalState = ProductModalState<PrescriptionProductModalData>
const prescriptionProductModalState = productModalStateMethods<PrescriptionProductModalData>()
const [prescriptionProductModalState$, setPrescriptionProductModalState] = createSignal<
  PrescriptionProductModalState
>()
const [usePrescriptionProductModalState] = bind<PrescriptionProductModalState>(
  prescriptionProductModalState$,
  prescriptionProductModalState.closed()
)
export { usePrescriptionProductModalState }

// open modal

export type OpenPrescriptionProductModalInput = OpenProductModalInput & {
  mode: 'view' | 'edit'
}
const [openPrescriptionProductModal$, openPrescriptionProductModal] = createSignal<
  OpenPrescriptionProductModalInput
>()
export { openPrescriptionProductModal }

// close modal

const [closePrescriptionProductModal$, closePrescriptionProductModal] = createSignal<void>()
export { closePrescriptionProductModal }

// process signals

const { open$, close$ } = processProductModalSignals({
  productModalState: prescriptionProductModalState,
  openProductModal$: openPrescriptionProductModal$,
  closeProductModal$: closePrescriptionProductModal$,
  setProductModalState: setPrescriptionProductModalState,
})

open$.subscribe()
close$.subscribe()
